.buy-insurance {
  animation: fadeIn 0.7s;
  & h1 {
    align-self: flex-start;
  }

  &__details {
    animation: fadeIn 0.7s;
  }
}
