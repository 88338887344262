.list {
  table {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    border: 1px solid rgb(177, 177, 177);
    font-size: 2rem;

    thead {
      tr {
        th {
          width: max-content;
        }
      }
    }

    tbody {
      tr {
        &:nth-child(even) {
          background-color: #d1d1d1;
        }

        &:nth-child(odd) {
          background-color: #fafafa;
        }
      }
    }
  }

  th {
    font-size: 2rem;
    font-weight: 400;
  }

  td {
    font-size: 1.5rem;
    font-weight: 300;
  }
}
