.certificate-container {
  background: #fff;
  width: 100%;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  &__navbar {
    background: #ececec;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    width: 100%;

    &__links {
      font-weight: 500;
      font-size: 1.5rem;
      color: #9b2743;
      text-decoration: inherit;

      &:hover {
        color: #777;
      }
    }
  }

  &__footer {
    background-color: #9b2743;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .footer-copyright {
      text-align: center;
      color: white;
      font-size: 1.2rem;
    }

    .footer-links {
      display: flex;
      justify-content: space-between;
      align-items: center;
      list-style: none;
      font-size: 2rem;

      a {
        color: #ececec;
      }

      li {
        margin-right: 10px;
      }
    }
  }

  .inactive {
    border: none;
    background: gray;
    padding: 1rem 1.5rem;
    border-radius: 0.7rem;
    font-size: 1.7rem;
    transition: all 0.3s;
    cursor: not-allowed;
  }
}

@media screen and (max-width: 768px) {
  .wrapper {
    .main-content {
      height: 100dvh;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}
