.select-text {
  font-size: 2rem;
  font-weight: 500;
}

.select-button {
  cursor: pointer;
}

.plan-link {
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: 300;
  text-decoration: underline;
  text-underline-offset: 0.5rem;
  color: rgb(119, 182, 250);

  &:hover {
    color: #000;
  }
}
