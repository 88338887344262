.login-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3rem;
  font-size: 2rem;

  & img {
    transform: scale(0.8);
  }
  & a {
    text-decoration: none;
    color: inherit;
  }
}
.login-page {
  position: relative;

  min-height: 100vh;

  &__bottom-left {
    position: absolute;
    bottom: -0rem;
    left: -10rem;
  }
}
.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 45rem;
  max-width: calc(100% - 4rem);
  margin: 5rem auto;

  a,
  a:hover,
  a:active {
    text-decoration: none;
    font-size: 2rem;
    color: var(--color-primary);
  }

  & h1 {
    font-size: 4.5rem;
    border-bottom: 2px solid var(--color-primary);
    padding-bottom: 1rem;
    color: #000;
    opacity: 0.7;
  }

  & p {
    font-size: 2.2rem;
    text-align: center;

    & span {
      color: var(--color-primary);
    }
  }

  &__button {
    background-color: var(--color-primary);
    align-self: stretch;
    margin-top: 2rem;
    padding: 1.25rem;
    color: white;
    font-size: 1.8rem;
    border: none;
    border-radius: 1rem;
    transition: background-color 0.3s;

    &:hover {
      background-color: var(--color-primary-dark);
    }
  }
}

.background {
  background-color: #ffffff;
}
.header {
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.05em;
  text-align: center;
  float: center;
}
.bg-teal {
  padding-top: 8%;
  color: black;
  font-size: 2em;
}
.jumbotron > hr {
  width: 5em;
  background: linear-gradient(180deg, #96273c 0%, rgba(156, 28, 52, 0) 100%);
}
.form-group {
  margin: auto !important;
}
.btn btn-block {
  width: 40px !important;
}
