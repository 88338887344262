*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  // defines what 1rem is
  font-size: 64%; // 1rem = 10px
  // font-size: 62.5%; // 1rem = 10px
  height: 100%;
}

body {
  box-sizing: border-box;
  background-color: white;
  height: 100vh;
  background-attachment: fixed;
  background-repeat: no-repeat;
  overflow-x: hidden !important;
}

input {
  border-radius: 0;
}

.app__loader {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;

  .spinner-border {
    width: 10rem;
    height: 10rem;
    border: 0.5rem solid var(--color-primary);
    opacity: 0.7;
    border-right-color: transparent;
  }
}

hr {
  color: var(--color-border) !important;
  border-color: var(--color-border) !important;
  background-color: var(--color-border) !important;
  opacity: 1 !important;
  height: 2px;
}

.pagination {
  font-size: 1.5rem;

  .page-link {
    padding: 0.75rem 1rem;
    color: var(--color-primary) !important;
  }

  .page-item.active .page-link {
    color: #fff !important;
    background-color: var(--color-primary) !important;
    border-color: var(--color-primary) !important;
  }

  .sr-only {
    display: none !important;
  }
}

.App .container {
  padding: 1.4rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
