.notfound {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 4rem;
  flex-direction: column;

  .img {
    width: 500px;

    @include respond(mobile) {
      width: 80vw;
    }
  }

  h1 {
    margin-bottom: 1rem;
    font-size: 3rem;
    font-weight: 600;
    color: rgb(75, 73, 73);
  }

  h3 {
    color: rgb(75, 73, 73);
    font-size: 1.7rem;
  }

  &__texts {
    text-align: center;
  }

  .button {
    margin-top: 3rem;

    & button {
      background-color: var(--color-primary);
      color: white;
      padding: 1.2rem;
      border: none;
      min-width: 100%;
      border-radius: 0.7rem;
      cursor: pointer;
      transition: all .3s;

      &:hover {
        background-color: var(--color-primary);
      }
    }
  }
}