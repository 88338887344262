.batch {
  display: grid;
  grid-template-columns: 30rem 1fr;
  height: 100vh;

  .inactive {
    // styling for button-primary, but with gray color and not allowing pointer-events
    background-color: gray;
    border: none;
    color: white;
    padding: 1rem 1.5rem;
    border-radius: 0.7rem;
    font-size: 1.7rem;
    transition: all 0.3s;
    cursor: not-allowed;
  }
}
