.header {
  display: flex;
  justify-content: space-between;
  // align-items: center;
  padding: 2rem 0;

  &__mobile-toggle {
    display: none;

    &:hover {
      cursor: pointer;
    }
  }

  &__logo {
    width: 185px;
    height: 48px;
    align-items: center;
  }

  &__links {
    list-style: none;
    display: flex;
    align-items: center;
    animation: fadeIn 0.3s;
  }
  &__link {
    & a {
      font-size: 1.6rem;
      font-weight: 500 !important;
      &:visited,
      &:link {
        text-decoration: none;
        color: var(--color-headings);
      }
    }
    &:not(:last-child) {
      margin-right: 3.5rem;
    }

    &:hover {
      cursor: pointer;
    }

    &__dashboard {
      background-color: var(--color-primary);
      padding: 0.9rem 1.2rem;
      border-radius: 1rem;
      transition: all 0.3s;
      & a {
        color: white !important;
      }
      &:hover {
        background-color: var(--color-primary-dark);
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .header {
    flex-shrink: 1;
    flex-wrap: wrap;

    &__mobile-toggle {
      display: inline-flex;
      align-items: center;

      svg {
        height: 3rem;
        width: 3rem;
      }
    }
    position: relative;

    & &__links_show {
      display: block;
    }

    &__links {
      display: none;
      position: absolute;
      top: 6rem;
      left: 0;
      z-index: 10;
      flex-shrink: 0;
      width: 100%;
      margin-top: 2rem;

      padding: 0;

      &_dashboard,
      li {
        background-color: #ffffff;
        padding: 0.9rem 1.2rem;
        border-radius: unset;
        transition: all 0.3s;
        margin-right: 0 !important;
        border-top: solid 2px var(--color-border);

        &:last-child {
          border-bottom: solid 2px var(--color-border);
        }

        a {
          color: var(--color-gray) !important;
        }

        &:hover {
          background-color: var(--color-primary-faint);
        }
      }
    }
  }
}
