.insurance-documents {
  animation: fadeIn 0.7s;
  display: flex;
  flex-direction: column;

  &__heading {
    margin-top: 2rem;
    font-size: 2rem;
  }

  &__sub-heading {
    color: var(--color-primary);
    font-size: 1.5rem;
  }

  &__upload {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 1rem;
    background-color: var(--color-primary-faint);
    height: 3.5em;
    margin-left: 20px;

    .upload-text {
      margin-left: 1.25rem;
    }

    & p {
      font-size: 1.5rem;
      margin: 2rem 0;
    }
  }

  @media only screen and (max-width: 37.5em) {
    .upload-text {
      font-size: smaller;
    }
    &__upload {
      margin-bottom: 1em;
      padding: 0.5em;
      margin-left: 0;
    }
  }
}

.uploaded-file {
  font-size: 1.5rem;
  padding: 1rem;
  background-color: var(--color-primary-faint);
  color: var(--color-primary);
  margin-top: 2rem;
  border-radius: 5px;
}
