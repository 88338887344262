.sidebar {
  padding: 2rem 3.5rem;
  background-color: var(--color-primary-faint);
  height: 100%;
  min-height: 100dvh;
  width: 100%;
  max-width: 25rem;
  position: fixed;

  &_toggle {
    display: none;
    position: fixed;
    top: 2rem;
    right: 2rem;
    font-size: 4rem;
    line-height: 1;
    height: 4rem;
    width: 4rem;
    border-radius: 3rem;
    background-color: var(--color-primary);
    color: #fff;
    justify-content: center;
    align-items: center;
    padding-bottom: 0.75rem;
    cursor: pointer;
    animation: slideInRight 0.5s;
  }

  &__links {
    list-style: none;
    margin-top: 4rem;

    & li {
      display: flex;
      align-items: center;
      cursor: pointer;
      &:not(:last-child) {
        margin-bottom: 2rem;
      }
      & svg {
        transform: scale(0.8);
        fill: var(--color-gray);
        transition: all 0.3s;
      }

      & span {
        font-size: 1.4rem;
        margin-left: 1.5rem;
        color: rgb(84, 82, 82);
        font-weight: 400;
        transition: all 0.3s;
      }

      &:hover,
      &.active {
        & span {
          margin-left: 2rem;
          color: var(--color-primary);
        }

        & svg {
          fill: var(--color-primary);
        }
      }
      &.active {
        & span {
          margin-left: 1.5rem;
        }
      }
    }
  }
}

.show-mobile {
  display: none;
}

@media screen and (max-width: 1024px) {
  .sidebar {
    animation: slideInLeft 0.3s;
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 30rem;
    z-index: 20;

    &_show {
      display: block !important;
    }

    &_toggle {
      display: inline-flex;
      z-index: 21;
    }

    &::before {
      content: '';
      top: 0;
      bottom: 0;
      left: 30rem;
      right: 0;
      background: #00000094;
      position: fixed;
    }
  }

  .hide-mobile {
    display: none;
  }

  .show-mobile {
    display: unset;
  }
}
