.quotes__steps {
  padding: 5rem 3.5rem;
  background-color: var(--color-primary-faint);
  height: 100vh;

  &__items {
    list-style: none;
    margin-top: 6rem;
    display: flex;
    flex-direction: column;
  }

  &__item {
    cursor: pointer;
    position: relative;
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    border: 1px solid var(--color-primary);

    &--active {
      background-color: var(--color-primary);

      &::after {
        content: '';
        position: absolute;
        left: -1%;
        right: 0;
        background-color: var(--color-primary);
        height: 2rem;
        width: 2rem;
        display: block;
        padding: 0;
        border-radius: 50%;
        animation: bubbleOut 2.5s;
      }
    }

    &__text {
      position: absolute;
      left: 4rem;
      white-space: nowrap;
      font-size: 1.4rem;
      color: var(--color-primary);
    }
  }

  &__line {
    width: 0.1rem;
    height: 3rem;
    background-color: var(--color-primary);
    margin-left: 1rem;
  }
}
