:root {
  //colors
  --color-headings: #131212;
  --color-primary: #9c1c34;
  --color-primary-transparent: #9c1c3444;
  --color-primary-dark: #791427;
  --color-gray: rgb(70, 70, 70);
  --color-border: #f3f4f5;
  --color-primary-faint: rgb(250, 237, 239);
}
