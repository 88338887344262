.passenger-details {
  width: 100%;

  &__container {
    background: #fff;
    border-radius: 10px;
    padding: 4rem;
    display: flex;
    flex-direction: column;
    box-shadow: 3px 4px 3px 2px #676767;
  }

  .active {
    pointer-events: auto;
  }

  .inactive {
    // styling for button-primary, but with gray color and not allowing pointer-events
    background-color: gray;
    border: none;
    color: white;
    padding: 1rem 1.5rem;
    border-radius: 0.7rem;
    font-size: 1.7rem;
    transition: all 0.3s;
    cursor: not-allowed;
    pointer-events: none;
  }
}

@media screen and (max-width: 1024px) {
  .passenger-details {
    grid-template-columns: 1fr;

    &__main {
      padding: 1rem;

      &__container {
        padding: 2rem;

        &__button-group {
        }
      }
    }
  }
}
