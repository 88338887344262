@keyframes bubbleOut {
  0% {
    opacity: 0.5;
    transform: scale(1.7) rotate(180deg);
  }
  100% {
    opacity: 1;
    transform: scale(1) rotate(180deg);
  }
}

@keyframes slideInLeft {
  0% {
    opacity: 0;
    transform: translateX(-20rem);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0.5) translateY(0.2rem);
  }
  60% {
    opacity: 0;
  }
  70% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}
