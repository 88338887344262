.button-primary {
  background-color: var(--color-primary);
  border: none;
  color: white;
  padding: 1rem 1.5rem;
  border-radius: 0.7rem;
  font-size: 1.7rem;
  transition: all 0.3s;
  cursor: pointer;

  &:focus {
    outline: none;
  }
  &:hover {
    background-color: var(--color-primary-dark);
  }
}

.button-secondary {
  border: none;
  color: #fff;
  padding: 1rem 1.5rem;
  border-radius: 0.7rem;
  font-size: 1.7rem;
  transition: all 0.3s;
  cursor: pointer;
  color: var(--color-primary);

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: #fddede;
  }
}

.button-rounded {
  padding: 1rem 4rem;
  font-size: 1.4rem;
  border-radius: 50rem;
  border: none;
  background-color: var(--color-primary);
  color: white;
  font-weight: 500;
  transition: all 0.3s;

  &:hover {
    background-color: var(--color-primary-dark);
  }

  &--outline {
    background-color: transparent;
    border: 1px solid var(--color-primary);
    color: var(--color-primary);

    &:hover {
      background-color: var(--color-primary-faint);
    }
  }
}
