.home {
  &__news-letter {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--color-primary-faint);
    margin-top: 6rem;
    padding: 6rem 2rem;
    border-radius: 1rem;

    &__heading {
      font-size: 3.5rem;
    }
    &__sub-heading {
      font-size: 2rem;
      margin: 2rem 0;
      color: gray;
    }

    &__input {
      // width: 40%;
      background-color: white;
      display: grid;
      grid-template-columns: 1fr 0.3fr;
      padding: 0.3rem;
      border-radius: 1rem;

      & input {
        border: none;
        padding-left: 1rem;
        padding-right: 1.5rem;
        font-size: 1.5rem;
        &:focus {
          outline: none;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .home {
    &__news-letter {
      text-align: center;

      &__heading {
        font-size: 3rem;
      }
      &__sub-heading {
        font-size: 1.6rem;
        margin: 2rem 0;
      }

      &__input {
        // width: 40%;
        background-color: white;
        display: grid;
        grid-template-columns: 1fr 0.3fr;
        padding: 0.3rem;
        border-radius: 1rem;

        & input {
          border: none;
          padding-left: 0.5rem;
          padding-right: 0.5rem;
          font-size: 1.4rem;
          &:focus {
            outline: none;
          }
        }
      }
    }
  }
}
