.footer {
  margin-top: 8rem;
  // padding: 0 4rem;

  &__top {
    display: flex;
    justify-content: space-between;
    border-bottom: 3px solid rgb(237, 235, 235);
    // padding-bottom: 4rem;

    ol,
    ul {
      padding-left: 0;
    }

    &__list {
      list-style: none;
      font-size: 1.4rem;
    }

    &__item {
      padding: 1rem 0;

      &:first-child {
        font-size: 1.5rem;
        font-weight: 500;
      }

      & a {
        &:visited,
        &:link {
          text-decoration: none;
          color: var(--color-gray);

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 0;

    & span {
      font-size: 1.1rem;
      font-weight: 500;
    }

    &__socials {
      img {
        transform: scale(0.8);
      }
      & a:not(:last-child) {
        margin-right: 2.5rem;
      }
    }
  }
}
