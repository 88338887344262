.group {
  display: grid;
  grid-template-columns: 30rem 1fr;
  height: 100vh;

  &__main {
    // width: 100%;
    padding: 4rem;
  }
}
