.formGroup2 {
  width: 100%;
 
  &__input {
    width: 100%;
    border: none;
    border-bottom: 3px solid var(--color-border);
    padding: 1rem 0;
    font-size: 2rem;
 
    &:focus {
      outline: none;
    }
  }
}
.formGroup3__input{
    border: none;
    border-bottom: 3px solid var(--color-border);
    padding: 1rem 0;
    font-size: 2rem;
}