.in-between {
  height: 100vh !important;
  width: 100dvw;
  display: flex;

  &__sidebar{
    width: 20%;
  }

  &__main {
    width: 80%;
  }

  &__text {
    font-size: large !important;
  }
}
