.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3.5rem 0 1.5rem 0;
  border-bottom: 2px solid var(--color-border);

  &__title {
    color: #000;
    opacity: 0.5;
  }

  a {
    text-decoration: none;
  }

  h1 {
    line-height: 1;
  }

  &__menu-toggle {
    cursor: pointer;
    margin-right: 1rem;

    svg {
      width: 2.5rem;
      height: 2.5rem;
      fill: var(--color-primary);
    }
  }

  &__menu {
    display: flex;
    align-items: center;

    & img {
      &[alt='alert'] {
        transform: scale(0.7);
        margin-right: 1.5rem;
      }
    }

    &__avatar {
      display: inline-block;
      margin: 0;
      border-radius: 50%;
      margin-right: 1.5rem;
      overflow: hidden;

      .text {
        background: var(--color-primary-faint);
        height: 30px;
        width: 30px;
        justify-content: center;
        align-items: center;
        display: inline-flex;
        border-radius: 50%;
      }
    }

    &__user {
      font-size: 1.3rem;
      color: #000;
      opacity: 0.5;
      font-weight: 400;
      margin-right: 1.5rem;
    }
  }
}
