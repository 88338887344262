.quote-details {
  margin: 0 auto;

  max-width: 1320px;

  button:disabled {
    opacity: 0.85;
  }

  &__form {
    margin-top: 0.5rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 2rem;
  }

  &__buttons {
    grid-column: 1 / -1;
    margin: 4rem 0;
    display: flex;
    justify-content: space-between;
  }

  &__options {
    grid-column: 1 / -1;
    margin-top: 3rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-between;

    border-top: 2px solid var(--color-border);
    // border-bottom: 2px solid var(--color-border);
    padding: 1.2rem 0;

    & img {
      justify-self: end;
      transform: scale(0.7);
      cursor: pointer;
    }

    & span {
      font-size: 1.4rem;
      cursor: pointer;
    }

    &--3 {
      border: none;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      justify-content: start;
      margin-top: 0.5rem;
    }

    &--1 {
      font-size: 1.4rem;
      border: none;
      display: block;
      grid-template-columns: unset;
      margin-top: 0.5rem;
      border-top: 2px solid var(--color-border);
    }

    &__guardian {
      border-bottom: 2px solid var(--color-border);
    }
  }

  table {
    font-size: 1.4rem;

    .table-heading-condense,
    .table-heading {
      font-weight: 600;
      font-size: 1.5rem;
      padding-top: 2rem;
      color: var(--color-primary);
    }

    .table-heading-condense {
      padding-top: unset;
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    padding-top: 1vh;
  }

  &__product_loader {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 0;
    width: 100%;
    height: 60vh;
  }

  &__loader {
    div {
      text-align: center;
    }

    button {
      background-color: transparent;
      border: solid 1px var(--color-primary);
      padding: 0.7rem 2.5rem;
      border-radius: 10rem;
      color: var(--color-primary);
      font-size: 1.5rem;
      display: inline-flex;
      align-items: center;

      svg {
        margin-right: 0.5rem;
        width: 1.5rem;
        height: 1.5rem;
        fill: var(--color-primary);
      }
    }

    .spinner-border {
      width: 5rem;
      height: 5rem;
      border: 0.5rem solid var(--color-primary);
      opacity: 0.7;
      border-right-color: transparent;
    }
  }

  &__select_plan {
    position: relative;
    font-size: 1.5rem;

    .card {
      margin-bottom: 3rem;
      border: none;

      &-footer {
        display: flex;
        // justify-content: flex-end;
        background-color: transparent;
        border: none;
      }

      &-header {
        background-color: transparent;
        border: none;
        border-bottom: 2px solid var(--color-border);
        font-size: 1.6rem;
        color: var(--color-primary);
      }

      &-body {
        border: none;
      }

      button {
        background-color: transparent;
        border: solid 1px var(--color-primary);
        padding: 0.5rem 1.2rem;
        border-radius: 0.4rem;
        color: var(--color-primary);
        font-size: 1.4rem;
      }

      button:hover {
        background-color: var(--color-primary);
        color: #fff;
        transition: all 0.5s;
      }

      button:disabled {
        background-color: #eeeeee;
        cursor: no-drop;
        color: var(--color-primary);
      }
    }
  }

  &__product_details {
    .coverage_details {
      text-transform: capitalize;
    }

    &__main {
      margin: 1.25rem 0;
      font-size: 1.5rem;
      line-height: 2;

      &__name {
        font-size: 1.8rem;
        text-transform: capitalize;
        color: var(--color-primary);
        font-weight: 500;
        border-bottom: 1px solid var(--color-border);
      }

      .subtitle {
        display: inline-flex;
        margin-right: 0.5rem;
        font-weight: 500;
        text-transform: capitalize;
      }

      .product-details {
        line-height: 1.7;
        margin-top: 1.25rem;

        & > div {
          border-bottom: 1px solid var(--color-border);
        }

        & > div:first-child {
          border-top: 1px solid var(--color-border);
        }
      }

      .product-detail {
        display: inline-flex;
      }
    }
  }

  // .passport-upload {
  //   margin-top: 3rem;
  //   font-size: 2rem;
  //   padding: 0.5rem;
  //   background-color: var(--color-primary-faint);
  //   border-radius: 5px;

  //   cursor: pointer;

  //   .dropzone {
  //     border: 2px dashed #dedede;
  //     border-radius: 5px;
  //     padding: 5vh 0;
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //   }
  // }

  .referral {
    padding: 0;
  }
}

.passport-upload {
  margin-top: 3rem;
  font-size: 2rem;
  padding: 0.5rem;
  background-color: var(--color-primary-faint);
  border-radius: 5px;

  cursor: pointer;

  .dropzone {
    border: 2px dashed #dedede;
    border-radius: 5px;
    padding: 5vh 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 37.5em) {
  .passport-upload {
    padding: 0;
  }
  .dropzone {
    padding: 3vh 0;
  }
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 992px) {
}
