.quotes {
  display: grid;
  grid-template-columns: 30rem 1fr;
  height: 100vh;
  overflow: hidden;
}

.show-mobile {
  display: none;
}

@media screen and (max-width: 1024px) {
  .quotes {
    grid-template-columns: 1fr;
    height: unset;
    overflow: unset;

    .quote-details__heading {
      margin-top: 4rem;
    }

    & &__main {
      height: unset;
      overflow: unset;
    }

    & &__steps {
      animation: slideInLeft 0.3s;
      height: unset;
      width: 100%;
      padding: 0;

      img {
        width: 180px;
      }

      &__items {
        padding: 0;
        margin: 0 0;
        margin-top: 0.75rem;
        padding-top: 0.75rem;

        border-top: 0.5px solid var(--color-primary-transparent);
      }

      &__item {
        display: none;

        &--active {
          display: inline-flex;
        }
      }

      &__line {
        display: none;
      }
    }

    .hide-mobile {
      display: none;
    }

    .show-mobile {
      display: unset;
    }
  }
}
