.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 1rem !important;
}

.mt-2 {
  margin-top: 2rem !important;
}

.mt-3 {
  margin-top: 3rem !important;
}

.mt-4 {
  margin-top: 4rem !important;
}

.mt-5 {
  margin-top: 5rem !important;
}

.mt-6 {
  margin-top: 6rem !important;
}

.mb-1 {
  margin-bottom: 1rem !important;
}

.mb-2 {
  margin-bottom: 2rem !important;
}

.mb-3 {
  margin-bottom: 3rem !important;
}

.mb-4 {
  margin-bottom: 4rem !important;
}

.mb-5 {
  margin-bottom: 5rem !important;
}

.mb-6 {
  margin-bottom: 6rem !important;
}

.disable-element {
  pointer-events: none;
  opacity: 0.7;
}

.Toastify__toast-body {
  font-size: 1.4rem;
}

.retry__component,
.retry__loader_fullscreen {
  position: fixed;
  z-index: 10;
  top: 0;
  background: #fff;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 10;
  align-items: center;
}

.retry__loader,
.retry__component {
  display: flex;
  justify-content: center;
  padding: 4rem 0;

  .spinner-border {
    width: 10rem;
    height: 10rem;
    border: 0.5rem solid var(--color-primary);
    opacity: 0.7;
    border-right-color: transparent;
  }

  p {
    font-size: 1.5rem;
    max-width: 300px;
  }

  button {
    background-color: transparent;
    border: solid 1px var(--color-primary);
    padding: 0.7rem 2.5rem;
    border-radius: 10rem;
    color: var(--color-primary);
    font-size: 1.5rem;
    display: inline-flex;
    align-items: center;
  }
}

.App .text-primary {
  color: var(--color-primary) !important;
}

.bg-primary-light {
  background-color: var(--color-primary-faint) !important;
}

.bg-success-light {
  background-color: rgb(158, 240, 149);
}

.close {
  box-sizing: content-box;
  width: 3rem;
  height: 3rem;
  padding: 0.5rem;
  color: #000;
  background: transparent;
  border: 0;
  border-radius: 0.5rem;
  opacity: 0.5;
  text-transform: uppercase;
  font-size: 3rem;
  line-height: 1;
  margin-top: -1rem;
  z-index: 2;

  .sr-only {
    display: none;
  }
}

.show-mobile {
  display: none;
}

.modal-dialog {
  button:not(.close) {
    background-color: transparent;
    border: solid 1px var(--color-primary);
    padding: 0.5rem 1.5rem;
    border-radius: 10rem;
    color: var(--color-primary);
    font-size: 1.4rem;
    display: inline-flex;
    align-items: center;
    margin: 0.2rem;

    &.main {
      background-color: var(--color-primary);
      color: #ffffff;
    }
  }
}

@media screen and (max-width: 1024px) {
  .hide-mobile {
    display: none;
  }

  .show-mobile {
    display: unset;
  }
}

.App {
  .custom-control-input:checked {
    + .custom-control-label::before {
      border-color: var(--color-primary);
      background-color: var(--color-primary);
    }
  }

  .custom-switch .custom-control-label::after {
    top: 0.6rem;
    left: calc(-2.55rem + 2px);
    width: calc(1rem);
    height: calc(1rem);
  }

  .custom-switch .custom-control-label::before {
    left: -2.75rem;
    width: 2.3rem;
    pointer-events: all;
    border-radius: 0.75rem;
    height: calc(1.4rem);
    top: 0.4rem;
  }
}
