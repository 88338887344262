.profile {
  animation: fadeIn 0.7s;
  &__tabs {
    &__heading {
      font-size: 1.7rem;
      font-weight: 700;
      color: #000;
      opacity: 0.5;
      display: flex;
      border-bottom: 2px solid var(--color-border);
      overflow-x: auto;
    }

    &__body {
      animation: fadeIn 0.7s;
    }
  }

  &__tab {
    cursor: pointer;
    padding: 1.25rem 1.25rem;
    flex-shrink: 0;

    &--active {
      border-bottom: 2px solid var(--color-primary);
    }
    &:not(:last-child) {
      margin-right: 1rem;
    }
  }

  &__information {
    margin-top: 3rem;
    animation: fadeIn 0.7s;

    & h3 {
      font-size: 1.7rem;
    }
    &__title {
      color: #000;
      opacity: 0.65;
    }

    &__photo {
      display: flex;
      align-items: center;
      & img {
        width: 7rem;
        height: 7rem;
        border-radius: 50%;
        margin-right: 2rem;
      }

      &__texts {
        & h3 {
          &:first-child {
            color: var(--color-primary);
          }
          &:last-child {
            color: #000;
            opacity: 0.5;
          }
        }
      }
    }
  }
}
