.session-login-modal {
  z-index: 100;
  background-color: var(--color-primary-faint);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  &-content {
    width: 400px;
    padding: 25px;
    border-radius: 30px;
    background-color: #fff;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  }
}
