.home {
  & &__welcome {
    margin-top: 5.5rem;
    margin-bottom: 7.5rem;

    &__text {
      &__heading {
        font-size: 4rem;
        font-weight: 500;
        // width: 50rem;
        color: var(--color-headings);
      }

      &__sub-heading {
        color: var(--color-gray);
        font-size: 1.8rem;
        margin: 3rem 0;
        // min-width: 50rem;
        line-height: 2.5rem;
      }
    }

    &__button-wrapper {
      display: flex;
      gap: 1rem;
    }

    &__images {
      align-self: center;
    }
  }
}

@media screen and (max-width: 1024px) {
  .home {
    & &__welcome {
      margin-top: 4.5rem;
      margin-bottom: 4.5rem;
      &__text {
        &__heading {
          font-size: 3.5rem;
          font-weight: 500;
          // width: 50rem;
        }

        &__sub-heading {
          font-size: 1.7rem;
          margin: 2rem 0;
          line-height: 2.5rem;
        }
      }

      &__images {
        img {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .home {
    overflow: hidden;
    & &__welcome {
      margin-top: 2.5rem;
      margin-bottom: 1rem;
      &__text {
        &__heading {
          font-size: 2.725rem;
          font-weight: 500;
        }

        &__sub-heading {
          font-size: 1.7rem;
          margin: 2rem 0 2.5rem 0;
          line-height: 2.5rem;
          margin-bottom: 2rem;
        }
      }

      &__images {
        margin: 2.5rem 0 2rem;
        text-align: right;
        // margin-top: -2rem;
        img {
          display: inline-flex;
          // width: 50vw;
          margin-left: auto;
          // margin-right: -2rem;
          width: 100%;
          margin-top: -90%;
          opacity: 0.1;
        }
      }
    }
  }
}
