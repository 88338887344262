.modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);

  &__close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 2rem;
    color: #333;
    background: transparent;
    border: none;
    outline: none;
    padding: 0;

    &:hover {
      color: red;
    }
  }

  &__content {
    background-color: white;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    width: 90%;
    height: 90dvh;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
  }
}
