.insurance-payment {
  display: flex;
  flex-direction: column;
  animation: fadeIn 0.7s;

  >svg {
    margin-top: 3rem;
    stroke: var(--color-primary);
    cursor: pointer;
  }

  &__card {
    margin-top: 5rem;
    align-self: center;
    border: 1px solid rgb(196, 190, 190);
    border-radius: 1rem;
    overflow: hidden;

    &__top {
      padding: 2.5rem 8rem;
      background-color: var(--color-primary-faint);

      & p {
        font-size: 1.3rem;
        margin-top: 1rem;
        color: var(--color-primary);
      }
    }

    &__description {
      font-size: 1.5rem;
      display: flex;
      justify-content: space-between;
      padding: 1.5rem;

      border-bottom: 1px solid var(--color-border);

      &:first-child {
        border-bottom: 1px solid var(--color-border);
      }

      & span {
        &:first-child {
          padding-right: .5rem;
          display: inline-block;
        }
      }

      & span {
        &:last-child {
          font-weight: 600;
          color: rgb(76, 71, 71);
          opacity: 0.6;
          padding-right: .5rem;
        }
      }
    }

    &__button {
      display: flex;
      justify-content: center;
      background-color: var(--color-primary);
      color: white;
      font-size: 1.5rem;
      font-weight: 600;
      padding: 1.5rem;
      cursor: pointer;
      transition: all 0.3s;

      &:hover {
        background-color: var(--color-primary-dark);
      }
    }
  }
}