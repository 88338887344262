.home__plans {
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  animation: fadeIn 0.7s;

  &__heading {
    font-size: 2.5rem;
    font-weight: 400;
    align-self: center;
    font-weight: 500;
  }

  &__search {
    display: grid;
    grid-template-columns: 1fr 0.3fr;
    border: 2.5px solid var(--color-border);
    padding: 1.2rem;
    border-radius: 1rem;
    margin: 3rem 0;

    & > input {
      margin-right: 2rem;
      border: 1.5px solid var(--color-border);
      padding: 0.5rem;
      border-radius: 1rem;
      font-size: 1.5rem;

      &:focus {
        outline: none;
      }
    }
  }

  &__table {
    display: grid;
    grid-template-columns: [table-left-start]0.3fr [table-right-start]1fr[table-right-end];
    margin-top: 2rem;

    &__heading {
      border-bottom: 2px solid var(--color-border);
      padding-bottom: 2rem;
    }

    &__plan {
      display: grid;
      grid-template-columns: 0.3fr 1fr;
      grid-column: 1 / -1;
      border-bottom: 1px solid var(--color-border);
      cursor: pointer;

      &:hover {
        background-color: #f1f1f120;
      }

      &__name {
        font-size: 1.6rem;
        padding: 1rem 0;
        cursor: pointer;
      }

      &__count {
        display: flex;
        justify-content: space-between;
        font-size: 1.5rem;
        padding: 1rem 0;

        & img {
          transform: scale(0.8);
          cursor: pointer;
        }
      }

      &:hover {
        & > span:first-child {
          color: var(--color-primary);
          font-weight: 600;
        }
      }

      &__details {
        // background-color: red;
        display: grid;
        grid-template-columns: 0.3fr 1fr;
        grid-column: 1 / -1;
        padding: 0;
        font-size: 1.5rem;
        color: var(--color-gray);
        padding-top: 1rem;
        transition: all 0.3s ease-in;
        overflow: hidden;

        & p {
          grid-column: 1 / -1;
        }

        &__description {
          border-bottom: 1px solid var(--color-border);
          padding-bottom: 1.5rem;
        }

        & li {
          grid-column: 2 / -1;
          display: flex;
          align-items: center;
          justify-content: space-between;

          margin-bottom: 2rem;

          & > div {
            & img {
              transform: scale(0.8);
              margin-right: 0.5rem;
            }

            & p {
              margin: 0;
            }
          }

          & button {
            padding: 0.5rem 1rem;
            border: 1px solid var(--color-primary);
            background: none;
            color: var(--color-primary);
            border-radius: 0.7rem;
            white-space: nowrap;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .home__plans {
    &__table {
      grid-template-columns: 1fr;

      &__plan {
        grid-template-columns: 1fr 1fr;
        &__count {
          justify-content: flex-end;
        }

        &__details {
          grid-template-columns: unset;
          font-size: 1.3rem;

          li {
            padding: 0.75rem;
            background-color: var(--color-primary-faint);
            margin-bottom: 0.75rem;
            border-radius: 0.5rem;
            align-items: flex-end;

            button {
              background-color: #ffffff;

              padding: 0.5rem 0.75rem;
            }
          }
        }
      }
    }
  }
}

// & button {
//   background: none;
//   border: 1px solid var(--color-primary);
//   padding: 0.7rem;
//   border-radius: 1rem;
//   font-size: 1.2rem;
//   color: var(--color-primary);
//   transition: all 0.3s;

//   &:hover {
//     background: var(--color-primary);
//     color: white;
//     font-weight: 500;
//   }
// }
