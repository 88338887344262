.travel-details {
  // width: 100%;

  &__heading {
    padding: 1rem 0;
    font-weight: 600;
    font-size: 3rem;
  }

  &__container {
    background: #fff;
    border-radius: 10px;
    padding: 4rem;
    display: flex;
    flex-direction: column;
    box-shadow: 3px 4px 3px 2px #676767;

    .row {
      .heading,
      .col-sm-6 {
        padding-left: 0;
      }
    }
  }
}

.card-header,
.card-body {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.button {
  width: 30%;
  align-self: center;
}

.active {
  pointer-events: auto;
}

.inactive {
  // styling for button-primary, but with gray color
  background-color: gray;
  border: none;
  color: white;
  padding: 1rem 1.5rem;
  border-radius: 0.7rem;
  font-size: 1.7rem;
  transition: all 0.3s;
  cursor: not-allowed;
}

@media screen and (max-width: 1024px) {
  .travel-details {
    grid-template-columns: 1fr;

    .main {
      padding: 1rem;

      &__container {
        padding: 2rem;

        .row {
          .heading,
          .col-sm-6 {
            padding-left: 1rem;
          }
        }
      }
    }
  }

  .button {
    width: 100%;
  }
}
