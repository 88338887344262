.dashboard {
  display: grid;
  grid-template-columns: 30rem 1fr;
  height: 100vh;
  overflow: hidden;

  &__main {
    padding: 0 4rem;
    padding-bottom: 5rem;
    overflow-y: auto;
  }
}

@media screen and (max-width: 1024px) {
  .dashboard {
    grid-template-columns: 1fr;

    &__main {
      padding: 0 0;
      overflow-y: auto;
    }
  }
}
