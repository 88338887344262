.dashboard-home {
  margin-top: 3rem;
  animation: fadeIn 0.3s;

  font-size: 1.4rem;

  &__cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 2rem;
  }

  &__card {
    display: flex;
    align-items: center;
    // justify-content: space-around;
    padding: 1.8rem 1.2rem;
    border-radius: 1rem;
    flex-grow: 1;

    svg {
      width: 4rem;
      min-width: 4rem;
      fill: #fff;
      margin-right: 1rem;
      opacity: 0.9;
    }

    &__texts {
      color: white;

      &__heading {
        font-size: 1.8rem;
        margin-bottom: 0;
        opacity: 0.9;
      }

      &__sub-heading {
        font-size: 1.2rem;
        margin-bottom: 0;
        opacity: 0.9;
      }
    }

    height: 100%;

    &-amount {
      background: linear-gradient(
          290.65deg,
          rgba(0, 0, 0, 0.2) 0.73%,
          rgba(0, 0, 0, 0) 40%
        ),
        #594fc9;
    }

    &-travels {
      background: linear-gradient(
          290.65deg,
          rgba(0, 0, 0, 0.2) 0.73%,
          rgba(0, 0, 0, 0) 40%
        ),
        #cf4343;
    }

    &-certificate {
      background: linear-gradient(
          290.65deg,
          rgba(0, 0, 0, 0.2) 0.73%,
          rgba(0, 0, 0, 0) 40%
        ),
        #1c9c85;
    }
  }

  &__table {
    margin-top: 3rem;

    &__title {
      color: #000;
      font-weight: 500;
      margin-bottom: 1.5rem;
    }

    & table {
      font-size: 1.5rem;
      color: #5a5858;

      & td {
        padding: 1.2rem 0.75rem;
      }

      .table-row-failed {
        background-color: var(--color-primary-faint);
      }

      .table-row-success {
        background-color: #36c30b18;
      }

      & thead {
        & th {
          background-color: var(--color-primary-faint);
          border-bottom: none;
          padding: 1.5rem 0.75rem;

          &:first-child {
            padding-left: 1rem;
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }

          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
      }

      & tbody {
        & th {
          padding-left: 1rem;
        }
      }
    }
  }

  &__tabs {
    &__heading {
      font-size: 1.7rem;
      font-weight: 700;
      color: #000;
      opacity: 0.5;
      display: flex;
      border-bottom: 2px solid var(--color-border);
    }

    &__body {
      animation: fadeIn 0.7s;
    }
  }

  &__tab {
    cursor: pointer;
    padding: 1.25rem 0.75rem;

    &--active {
      border-bottom: 2px solid var(--color-primary);
    }

    &:not(:last-child) {
      margin-right: 1rem;
    }
  }

  button {
    background-color: transparent;
    border: solid 1px var(--color-primary);
    padding: 0.25rem 1rem;
    border-radius: 10rem;
    color: var(--color-primary);
    font-size: 1.2rem;
    display: inline-flex;
    align-items: center;
    margin: 0.2rem;

    svg {
      fill: var(--color-primary);
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}
