.success-page {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height: 100vh;
  overflow: hidden;

  &__travellers {
    background-color: var(--color-primary-faint);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & img {
      width: 70%;
    }
  }

  &__texts {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    height: 100vh;
    overflow-y: auto;

    & img {
      transform: scale(0.7);
    }

    & p {
      font-size: 1.5rem;
      text-align: center;
      width: 60%;
      min-width: 30rem;
      line-height: 2.5rem;
    }
  }
}

.chi_certificate {
  position: relative;
  font-size: 1.4rem;

  overflow: hidden;

  * {
    background: transparent !important;
  }

  .logo_watermark {
    position: absolute;
    transform: rotate(320deg) scale(3) translate(0%, 200%);
    opacity: 0.07;
    filter: alpha(opacity=75);
    filter: grayscale(0);
  }
}

.chi_certificate.fullscreen {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
}

@media screen and (max-width: 768px) {
  .success-page {
    display: block;

    &__travellers {
      display: none;
    }
  }
}
