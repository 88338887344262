.passport {
  padding: 2rem 0;

  &__heading {
    display: flex;
    justify-content: flex-start;
    gap: 1rem;

    h1 {
      display: inline-block;
    }

    span {
      font-size: 2rem;
    }
  }

  &__file {
    font-size: 1.5rem;
    padding: 1rem;
    background-color: var(--color-primary-faint);
    color: var(--color-primary);
    margin-top: 2rem;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 50%;
    min-width: max-content;

    &__group {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      gap: 1rem;
    }
  }

  &__text {
    color: #000;
    cursor: pointer;

    &:hover {
      color: var(--color-primary);
    }
  }
}

@media screen and (max-width: 1024px) {
  .passport {
    width: 100%;
    &__file {
    }
  }
}
