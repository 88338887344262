@import url('https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,700i,900,900i');
@import url('https://fonts.googleapis.com/css?family=Inter:300,300i,400,400i,500,500i,700,700i,900,900i');

body {
  font-weight: 400;
  line-height: 1.15;
  color: var(--color-text);
  font-family: Inter, Roboto, sans-serif !important;
}

.h2 {
  color: var(--color-headings);
  font-size: 1.5rem;
  font-weight: 700;
  margin: 0 1rem;
}

.h1 {
  color: var(--color-headings);
  font-size: 2rem;
  font-weight: 700;
}
