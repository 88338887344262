.searchable {
  max-width: 40rem;
  display: flex;

  margin-top: 1.75rem;
  margin-bottom: -0.75rem;

  input {
    padding: 1rem;
    border-radius: 0.7rem;
    border: 1px solid var(--color-border);
    font-size: 1.4rem;
    background-color: #fff;
    width: 100%;

    &:focus {
      outline: none;
      border: 1px solid var(--color-primary);
    }
  }

  button {
    margin: 0;
    color: #ffffff;
    border-radius: 0.7rem;
    background-color: var(--color-primary);
    margin-left: 1rem;
  }
}
