.inputGroup,
.col-sm-6,
.col-sm-12 {
  // display: flex;
  // flex-direction: column;

  & > label {
    font-size: 1.4rem;
    margin-bottom: 1.5rem;
    color: #828b94;
    font-weight: 700;
  }

  & > input,
  & > textarea,
  & > select {
    padding: 1rem;
    border-radius: 0.7rem;
    border: 1px solid var(--color-border);
    font-size: 1.4rem;
    background-color: #fff;
    width: 100%;

    &:focus {
      outline: none;
      border: 1px solid var(--color-primary);
    }
  }

  & .drop-search__dropdown__item--category span {
    font-size: 1.4rem !important;
    line-height: 1.5;
  }

  .react-select {
    font-size: 1.4rem;
    & > div {
      padding: 0.2rem 0;
      border-radius: 0.7rem;
      border: 1px solid var(--color-border);
    }
  }

  input:read-only {
    background-color: #f9f9f9;
  }
}
